/* 
 * On met body en display none par défaut puis on le repasse en initial dans le fichier de style
 * pour éviter de voir pendant une courte période, l'affichage non stylisé au début du chargement de la page 
 */
body {
    display: initial !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 60px var(--acadio-login-page-input-background-color) inset , 0px 1px 2px 0px rgba(0, 0, 0, 0.08) !important;
    background-color: var(--acadio-login-page-input-background-color) !important;
    background-clip: content-box !important;
    font-size: 14px !important;
}

.input-container input {
    height: 40px;
    line-height: 40px;
}

.input-container {
    position: relative;
}

.input-container .input-container-icon {
    position: absolute;

    left: 0;
    top: 0;
    
    z-index: 1000;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 40px;
}

.input-container .input-container-icon .input-icon {
    font-size: 21px;
    color: var(--acadio-login-page-input-icon-color);
    
    display: inline-flex;

    height: 1em !important;
    width: 1em !important;

    vertical-align: baseline;
    fill: currentColor;
}

.input-container .input-container-icon .input-icon svg {
    width: 100%;
    height: 100%;
}

.actions-container {
    display: flex;
    flex-direction: column;
    gap: 0.35rem;
}

.cover-container {
    position: relative;
}

.content-svg {
    position: absolute;
    margin: 50px;
    z-index: 2;
}

#login-form {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 0 auto;
    gap: 1.25rem;
}


#login-btn {
    background-color: #3475F8;
    height: 54px;
    font-weight: 500;
}

#login-btn:hover,
#login-btn:active {
    background: #0b5ed7;
}

.bottom {
    width: 100%;
    height: auto;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    bottom: 0;
    padding: 2em;
    background-color: #3475F8;
}

.cover-aside .bottom {
    z-index: 1000;
}

.cover-aside {
    background-color: #3475F8 !important;
}

.copyright>a {
    color: white;
    font-weight: bold;
}



.logo {
    margin-bottom: 1.25rem;
    width: 60%;
}

.copyright {
    display: block;
    font-size: 12px;
    text-align: center;
    margin: 0;
}

.cover-content {

    width: 100%;
    height: 90% !important;
}

#label-pass,
#label-login {
    font-weight: 500;
}

#error-message {
    padding-top: 1rem !important;
}

@media (max-width: 575px) and (max-height: 650px) {
    .content-svg {
        margin: 0;
        margin-top: 20px;
        right: 2rem;
    }
}

@media (min-width: 576px) and (orientation: landscape) {
    .cover-content {
        width: 62.5%;
        height: 100% !important;
    }

    #login-form {
        width: 50%;
        padding-top: 5rem;
    }

    .bottom {
        background: none;
    }

    .logo {
        margin-bottom: 2rem;
    }
}

/* -------------------------------------------------------------------------- */
/*                              Styles des inputs                             */
/* -------------------------------------------------------------------------- */

#input-login {
    text-transform: lowercase;

    &::placeholder {
        text-transform: none;
    }
}

.ht-input-text {
	display: flex;
	align-items: center;

	width: 100%;
	height: 40px;

	border: 1px solid var(--acadio-login-page-input-border-color);
	border-radius: 4px;
	
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);

	padding: 0 0.5rem;

	background-color: var(--acadio-login-page-input-background-color);
	color: var(--acadio-login-page-input-color);

	font-size: 14px;

    padding-left: 3.5rem;

    position: relative;
}

.ht-input-text::placeholder {
    color: #9C9C9C;
}

.ht-input-text:focus {
    border: 1px solid var(--acadio-blue);
}

.ht-input-text:focus-visible {
    outline: none;
}


/* -------------------------------------------------------------------------- */
/*                             Couleurs et thèmes                             */
/* -------------------------------------------------------------------------- */

body {
    
	--color-hinted-grey-1: #0e0e10;
    --color-hinted-grey-2: #18181b;
    --color-hinted-grey-3: #1f1f23;
    --color-hinted-grey-4: #26262c;
    --color-hinted-grey-5: #323239;
    --color-hinted-grey-6: #3b3b44;
    --color-hinted-grey-7: #53535f;
    --color-hinted-grey-8: #848494;
    --color-hinted-grey-9: #adadb8;
    --color-hinted-grey-10: #c8c8d0;
    --color-hinted-grey-11: #d3d3d9;
    --color-hinted-grey-12: #dedee3;
    --color-hinted-grey-13: #e6e6ea;
    --color-hinted-grey-14: #efeff1;
    --color-hinted-grey-15: #f7f7f8;
    --color-transparent: transparent;

    --cover-page-background-color: #FFF;
    --cover-content-background-color: #FFF;
    --cover-content-color: #000;
    --cover-content-svg-filter: none;
    --cover-page-input-background-color: #FFF;
    --cover-page-input-color: #000;
    --cover-page-error-message-color: red;

    --acadio-blue: #3475F8;

	--acadio-login-page-input-border-color: #D8DBE4;
	--acadio-login-page-input-background-color: #FFFFFF;
	--acadio-login-page-input-color: #000000;
    --acadio-login-page-input-icon-color: #6C6C6C;
}

body[data-acadio-theme="dark"] {
    --cover-page-background-color: var(--color-hinted-grey-1);
    --cover-content-background-color: var(--color-hinted-grey-3);
    --cover-content-color: var(--color-hinted-grey-11);
    --cover-content-svg-filter: invert(1) hue-rotate(180deg) brightness(1);
    --cover-page-input-background-color: var(--color-hinted-grey-2);
    --cover-page-input-color: var(--cover-content-color);
    --cover-page-error-message-color: #ff473c;

	--acadio-login-page-input-border-color: var(--color-hinted-grey-6);
	--acadio-login-page-input-background-color: var(--color-hinted-grey-1);
	--acadio-login-page-input-color: var(--color-hinted-grey-11);
    --acadio-login-page-input-icon-color: var(--color-hinted-grey-7);
}

.content-svg {
    filter: var(--cover-content-svg-filter);
}

.cover-page {
    background-color: var(--cover-page-background-color) !important;
}

.cover-content {
    background-color: var(--cover-content-background-color) !important;
    color: var(--cover-content-color);
}

input {
    background-color: var(--cover-page-input-background-color) !important;
    color: var(--cover-page-input-color) !important;
}

#error-message {
    color: var(--cover-page-error-message-color) !important;
}

.action-link {
    font-weight: 600;
}